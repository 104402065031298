<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="patientPreparation in patientPreparations"
        :key="patientPreparation.id"
        class="d-flex align-items-center"
      >
        <label class="checkbox mb-4">
          <input
            v-model="patientPreparation.checked"
            type="checkbox"
            :disabled="isUpdating"
            @input="changePreparation($event, patientPreparation)"
          />
          <span :class="`checkmark ${isUpdating ? 'checkmark-disabled' : ''}`">
            <Check />
          </span>
        </label>
        <span class="text">
          {{ patientPreparation.name }}
        </span>

        <span
          v-if="
            patientPreparation.professional?.name && patientPreparation.checked
          "
          class="ml-auto saved-info"
        >
          {{ patientPreparation.professional.name }}
          {{ formatDate(patientPreparation.updated_at) }}
        </span>
      </b-list-group-item>
    </b-list-group>

    <div v-if="isLoading">
      <b-skeleton width="100%" v-for="i in 3" :key="i" class="mb-2" />
    </div>
  </div>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
export default {
  name: 'PatientPreparation',
  props: {
    surgeryInformationId: String,
    patientId: String
  },
  components: {
    Check: () => import('@/assets/icons/check.svg')
  },
  data: () => ({
    patientPreparations: [],
    isLoading: false,
    isUpdating: false,
    clinic: getCurrentClinic(),
    user: getCurrentUser()
  }),
  async created() {
    await this.getSurgeryPatientPreparations()
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      return this.moment(date).format('[às] HH[h]mm [do dia] DD/MM/YYYY')
    },
    async getSurgeryPatientPreparations() {
      this.isLoading = true
      try {
        const { data } = await this.api.getSurgeryPatientPreparations(
          this.surgeryInformationId,
          this.clinic.id
        )
        this.patientPreparations = data
      } catch (error) {
        this.$toast.error('Erro ao carregar preparação do paciente')
      } finally {
        this.isLoading = false
      }
    },
    async updateSurgeryPatientPreparation(patientPreparation) {
      this.isUpdating = true
      try {
        await this.api.updateSurgeryPatientPreparation(patientPreparation.id, {
          clinic_id: this.clinic.id,
          checked: patientPreparation.checked
        })
      } catch (error) {
        throw new Error(error.message)
      } finally {
        this.isUpdating = false
      }
    },
    async changePreparation($event, patientPreparation) {
      patientPreparation.checked = $event.target.checked
      try {
        await this.updateSurgeryPatientPreparation(patientPreparation)
        patientPreparation.professional = this.user
        patientPreparation.updated_at = this.moment()
        const index = this.patientPreparations.findIndex(
          item => item.id === patientPreparation.id
        )
        this.$set(this.patientPreparations, index, patientPreparation)
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border-right: 0;
  border-top: 0;
  border-left: 0;

  .text {
    font-weight: 400;
    font-size: min(1.2vw, 16px);
    color: var(--type-active);
  }

  .saved-info {
    color: var(--type-placeholder) !important;
  }
}
</style>
